<template>
  <div class="app-wrapper">
    <div class="vertical-layout">
      <MenuPanelSide>
        <MenuSocialJet />
        <template #menu>
          <MenuSide />
        </template>
        <template #links>
          <MenuLinks />
        </template>
      </MenuPanelSide>
      <div class="page-with-top-panel flex flex-col w-[calc(100%-65px)]">
        <MenuPanelTop>
          <div class="gorizontal-menu flex-center justify-between">
            <h1 class="text-2xl font-bold">
              {{ handlerRouteName(route.name as string) }}
            </h1>
            <!-- переверстать верхнее меню -->
            <div class="flex-center-3">
              <div class="gorizontal-menu__button justify-align-center"></div>
            </div>

            <div class="flex items-center h-full">
              <template v-if="userModule.id">
                <NuxtLink
                  to="/adv/busket"
                  class="h-full px-[25px] border-l border-[#E5F1FF] flex items-center justify-center"
                >
                  <IBusket />
                </NuxtLink>
                <NuxtLink
                  to="/adv/notifications"
                  class="h-full px-[25px] border-l border-[#E5F1FF] flex items-center justify-center"
                >
                  <INotifications />
                </NuxtLink>
                <div
                  class="h-full pl-[20px] pr-[10px] border-l border-[#E5F1FF] flex items-center justify-center"
                >
                  <div
                    ref="target"
                    class="flex items-center gap-1 cursor-pointer relative"
                    @click="isOpenMenu = !isOpenMenu"
                  >
                    <div
                      class="min-w-8 min-h-8 rounded-full bg-gray-100 mr-1"
                    ></div>
                    <div class="text-[#556D80] text-nowrap">
                      {{ name || "Имя не указано" }}
                    </div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <path
                        d="M12.4716 5.46863C12.3466 5.34365 12.177 5.27344 12.0003 5.27344C11.8235 5.27344 11.654 5.34365 11.5289 5.46863L8.4716 8.52596C8.34485 8.64762 8.17596 8.71556 8.00027 8.71556C7.82458 8.71556 7.65569 8.64762 7.52894 8.52596L4.4716 5.46863C4.34587 5.34719 4.17747 5.27999 4.00267 5.28151C3.82787 5.28303 3.66066 5.35314 3.53706 5.47675C3.41345 5.60035 3.34334 5.76756 3.34182 5.94236C3.3403 6.11716 3.4075 6.28556 3.52894 6.4113L6.58561 9.46863C6.77133 9.65438 6.99182 9.80173 7.23449 9.90226C7.47717 10.0028 7.73727 10.0545 7.99994 10.0545C8.26261 10.0545 8.52271 10.0028 8.76538 9.90226C9.00806 9.80173 9.22855 9.65438 9.41427 9.46863L12.4716 6.4113C12.5966 6.28628 12.6668 6.11674 12.6668 5.93996C12.6668 5.76319 12.5966 5.59365 12.4716 5.46863Z"
                        fill="#556D80"
                      />
                    </svg>
                    <div
                      v-if="isOpenMenu"
                      class="absolute top-10 right-0 w-[200px] py-2 bg-white border rounded-lg z-50"
                    >
                      <NuxtLink
                        to="/adv/settings"
                        class="flex items-center gap-1 cursor-pointer px-4 py-2 hover:bg-gray-100 text-[#3c5467] font-normal"
                        >Настройки</NuxtLink
                      >
                      <NuxtLink
                        to="/publisher"
                        target="_blank"
                        class="flex items-center gap-1 cursor-pointer px-4 py-2 hover:bg-gray-100 text-[#3c5467] font-normal"
                        >Кабинет издателя</NuxtLink
                      >
                      <div
                        class="flex items-center gap-1 cursor-pointer px-4 py-2 hover:bg-gray-100 text-[#3c5467]"
                        @click="logout"
                      >
                        Выход
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <UIButtonSecondary
                v-else
                class="gorizontal-menu__aside-enter"
                @click="userModule.loginDialogVisible = true"
                >Войти</UIButtonSecondary
              >
            </div>
          </div>
        </MenuPanelTop>
        <div
          class="page-self flex-grow"
          :class="{ 'overflow-hidden': route.name === 'adv-dialogs' }"
        >
          <!-- основной слот для страницы -->
          <slot></slot>
        </div>
      </div>
    </div>

    <UIOverlay :view-overlay="true" />
    <ElDialog
      v-model="userModule.loginDialogVisible"
      class="wrapper-dialog flex justify-center max-w-[450px]"
    >
      <LoginScreen />
    </ElDialog>
  </div>
</template>
<script setup lang="ts">
import { ElDialog } from "element-plus";
import { useUserStore } from "~/store/UserModule";
import IBusket from "~/components/UI/icons/IBusket.vue";
import INotifications from "~/components/UI/icons/INotifications.vue";
import { onClickOutside } from "@vueuse/core";
import AuthService from "@/services/AuthService";

/* eslint-disable no-undef */
const route = useRoute();

const isOpenMenu = ref<boolean>(false);
const target = ref(null);

onClickOutside(target, (event) => {
  isOpenMenu.value = false;
});

const userModule = useUserStore();
const { name } = storeToRefs(userModule);

const handlerRouteName = (name: string) => {
  switch (name) {
    case "adv-orders-id":
      return "Мои заказы";
    case "adv-orders":
      return "Мои заказы";
    case "adv-order":
      return "Заказ";
    case "adv-creative-id":
      return "Креатив";
    case "adv-dialogs":
      return "Диалоги";
    case "adv-settings":
      return "Настройки";
    case "adv-appeals":
      return "Обращения";
    case "adv-creatives":
      return "";
    case "adv-support":
      return "Поддержка";
    case "adv-balance":
      return "Баланс";
    case "adv-news":
      return "Новости";
    case "adv-busket":
      return "Корзина";
    case "adv-notifications":
      return "Уведомления";
    case "adv-catalog":
      return "Каталог";
    case "adv-creative-create":
      return "Создание креатива";
    default:
      return name;
  }
};

const logout = () => {
  userModule.resetAuthKey();
  useCookie("accessToken").value = null;
};

// Запросить токен для пользователя
userModule.requestAuthKey();

onMounted(async () => {
  const data = await AuthService.getUser();
  name.value = data.telegram;
});
</script>

<style lang="scss">
.app-wrapper {
  height: 100%;
}

.vertical-layout {
  display: flex;
  flex-direction: row;
  height: 100dvh;
}

.page-with-top-panel {
  flex-grow: 1;
  margin-left: 65px;

  @media (max-width: 1230px) {
    margin-left: 0;
  }
}

.gorizontal-menu,
.vertical-menu {
  background: $white;
}

.el-menu + .screen-wrapper {
  margin-left: 70px;

  @media (max-width: $small-width) {
    margin-left: 0;
  }
}

.gorizontal-menu {
  @apply w-full px-5 z-50;
  height: 70px;

  &__aside {
    @apply h-full justify-end;

    &-user {
      width: fit-content;
      color: $light-gray;
      font-weight: 500;
    }

    &-notifications {
      width: fit-content;
      height: 100%;
      padding-left: clamp(20px, 2vw, 24px);
      padding-right: clamp(20px, 2vw, 24px);
      border-left: 1px solid rgba($main, 0.3);
      border-right: 1px solid rgba($main, 0.3);

      @media (max-width: $small-width) {
        border: none;
      }
    }
  }

  &__button {
    cursor: pointer;
    margin: 6px 0px;
    width: 36px;
    height: 36px;
    border-radius: 11px;
    border: 1px solid rgba($main, 0.2);
    flex-direction: column;
    gap: 5px;
    padding-left: 8px;
    padding-right: 8px;

    > div {
      width: 100%;
      height: 2px;
      background: $main;
      border-radius: 1px;
    }
  }
}

.gorizontal-menu .gorizontal-menu__button {
  display: none;
}

@media (max-width: $small-width) {
  .app-wrapper .vertical-menu.vertical-menu.el-menu--collapse {
    border-right: none;

    &:not(.horizontal-collapse-transition) {
      transform: translateX(-300px);
    }

    img {
      display: none;
    }
  }

  .gorizontal-menu .gorizontal-menu__button {
    display: flex;

    + a {
      z-index: 1;
    }
  }

  .gorizontal-menu {
    justify-content: space-between;
  }

  @keyframes close {
    to {
      transform: translateX(-300px);
    }
  }

  .vertical-menu:not(.el-menu--collapse).v-leave-active {
    animation: close 0.4s ease-in;
  }
}

button.gorizontal-menu__aside-enter {
  @apply px-3;
  max-width: min(35vw, 8rem);
}
</style>
