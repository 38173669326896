<template>
  <div class="layout-left-panel hover">
    <div class="layout-left-panel__box">
      <div class="logo-box">
        <slot>SocialJetLogo</slot>
      </div>
      <div class="menu-box">
        <slot name="menu">Menu</slot>
      </div>
      <div class="links-box">
        <slot name="links">Links</slot>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.layout-left-panel {
  width: 65px;
  background: white;
  box-shadow: 2px 0px 25px 0px #3D4C6D14;
  min-height: 100vh;
  overflow: hidden;
  z-index: 100;
  position: fixed;
  transition: all 0.3s ease;

  &:hover {
    width: 265px;
  }

  &__box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-width: 265px;
    width: 265px;
    height: 100%;
    background-color: rgba(255,255,255,0);
    transition: all 0.3s ease;
  }

  @media (max-width: 1230px) {
    width: 0 !important;
  }
}

.logo-box {
  opacity: 0;
}

:deep(.side-menu__item .text) {
  opacity: 0;
}

:deep(.side-menu__item .info) {
  opacity: 0;
}

:deep(.side-menu__separator) {
  opacity: 0;
}

.layout-left-panel:hover {
  transition: all 0.3s ease;

  & .layout-left-panel__box {
    background-color: rgba(255,255,255,1);
  }

  & .logo-box {
    opacity: 1;
  };

  & :deep(.side-menu__item .info) {
    opacity: 1;
  };

  & :deep(.side-menu__item .text) {
    opacity: 1;
  };

  :deep(.side-menu__separator) {
    opacity: 1;
  }
}

.logo-box {
  box-shadow: 2px 0px 25px 0px #3D4C6D14;
}
.menu-box {
  flex-grow: 1;
}
</style>
